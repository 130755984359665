import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Typography, TextField, Button, Alert, Divider, Dialog, DialogTitle, DialogActions } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const EditProfile = () => {
  const [userData, setUserData] = useState({ name: '', email: '' });
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordSuccess, setPasswordSuccess] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [openDialog, setOpenDialog] = useState(false); // State for dialog visibility
  const [loading, setLoading] = useState(true); // Loading state for profile data

  const navigate = useNavigate();

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/users/profile`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUserData(response.data);
      } catch (err) {
        setError('Failed to load profile data.');
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, []);

  // Handler for form input changes
  const handleChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  // Handler for profile update form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    setOpenDialog(true); // Open confirmation dialog
  };

  // Confirm and update profile information
  const confirmUpdate = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/users/profile`, userData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const tokenNew = response.data.token;
      localStorage.setItem("token", tokenNew);
      setSuccess('Profile updated successfully!');
      setTimeout(() => navigate(0) , 1500);
    } catch (err) {
      setError('Failed to update profile.');
    } finally {
      setOpenDialog(false); // Close the dialog after submission
    }
  };

  // Handler for password update form submission
  const handlePasswordUpdate = async (e) => {
    e.preventDefault();
    setPasswordError(null);
    setPasswordSuccess(null);

    if (newPassword !== confirmPassword) {
      setPasswordError('Passwords do not match.');
      return;
    }

    try {
      const token = localStorage.getItem('token');
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/users/profile/password`,
        { currentPassword, newPassword },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setPasswordSuccess(response.data.message);
    } catch (err) {
      setPasswordError(err.response?.data?.error || 'Failed to update password.');
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <Alert severity="error">{error}</Alert>;

  return (
    <Box sx={{ padding: 2, backgroundColor: '#282c34', color: '#fff', borderRadius: '8px' }}>
      <Typography variant="h4" gutterBottom>
        Edit Profile
      </Typography>

      {success && <Alert severity="success">{success}</Alert>}

      {/* Existing Profile Form */}
      <form onSubmit={handleSubmit}>
        <TextField
          label="Name"
          name="name"
          value={userData.name}
          onChange={handleChange}
          fullWidth
          margin="normal"
          variant="outlined"
          sx={{ backgroundColor: '#fff' }}
        />
        <TextField
          label="Email"
          name="email"
          value={userData.email}
          onChange={handleChange}
          fullWidth
          margin="normal"
          variant="outlined"
          sx={{ backgroundColor: '#fff' }}
        />
        <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
          Update Profile
        </Button>
      </form>

      {/* Confirmation Dialog */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Confirm Update</DialogTitle>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmUpdate} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Divider sx={{ my: 4 }} />

      <Typography variant="h5" gutterBottom>
        Update Password
      </Typography>

      {/* Password Update Form */}
      <form onSubmit={handlePasswordUpdate}>
        <TextField
          label="Current Password"
          type="password"
          name="currentPassword"
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
          fullWidth
          margin="normal"
          variant="outlined"
          sx={{ backgroundColor: '#fff' }}
        />
        <TextField
          label="New Password"
          type="password"
          name="newPassword"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          fullWidth
          margin="normal"
          variant="outlined"
          sx={{ backgroundColor: '#fff' }}
        />
        <TextField
          label="Confirm Password"
          type="password"
          name="confirmPassword"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          fullWidth
          margin="normal"
          variant="outlined"
          sx={{ backgroundColor: '#fff' }}
        />
        <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
          Update Password
        </Button>
      </form>

      {passwordError && <Alert severity="error" sx={{ mt: 2 }}>{passwordError}</Alert>}
      {passwordSuccess && <Alert severity="success" sx={{ mt: 2 }}>{passwordSuccess}</Alert>}
    </Box>
  );
};

export default EditProfile;

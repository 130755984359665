// components/ResetPassword.js
import React, { useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import {
  TextField,
  Button,
  CircularProgress,
  Typography,
  Alert,
  Box,
} from "@mui/material";

// Validation schema for new password
const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required("Password is required")
    .min(6, "Password must be at least 6 characters"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm password is required"),
});

const ResetPassword = () => {
  const { token } = useParams(); // Get token from URL
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data) => {
    setLoading(true);
    setSuccessMessage("");
    setErrorMessage("");

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/password/reset-password/${token}`, // Use token in URL
        {
          password: data.password, // Only send password in the request body
        }
      );
      setSuccessMessage(response.data.message); // Password successfully reset
      setTimeout(() => {
        navigate("/login"); // Redirect to login page after success
      }, 2000);
    } catch (error) {
      if (error.response && error.response.data) {
        setErrorMessage(error.response.data.error);
      } else {
        setErrorMessage("An unexpected error occurred");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        maxWidth: "400px",
        margin: "50px auto",
        padding: "30px",
        backgroundColor: "#2C2C2C",
        borderRadius: "12px",
        boxShadow: "0 4px 20px rgba(0, 0, 0, 0.5)",
      }}
    >
      <Typography
        variant="h4"
        align="center"
        gutterBottom
        sx={{ color: "#FFFFFF" }}
      >
        Reset Password
      </Typography>

      <TextField
        fullWidth
        label="New Password"
        type="password"
        variant="outlined"
        {...register("password")}
        error={!!errors.password}
        helperText={errors.password?.message}
        margin="normal"
        InputLabelProps={{ style: { color: "#B0B0B0" } }}
        InputProps={{ style: { color: "#FFFFFF" } }}
        sx={{
          "&:focus-within": { borderColor: "#4CAF50" },
          borderColor: "#555",
          bgcolor: "#444",
        }}
      />

      <TextField
        fullWidth
        label="Confirm Password"
        type="password"
        variant="outlined"
        {...register("confirmPassword")}
        error={!!errors.confirmPassword}
        helperText={errors.confirmPassword?.message}
        margin="normal"
        InputLabelProps={{ style: { color: "#B0B0B0" } }}
        InputProps={{ style: { color: "#FFFFFF" } }}
        sx={{
          "&:focus-within": { borderColor: "#4CAF50" },
          borderColor: "#555",
          bgcolor: "#444",
        }}
      />

      {errorMessage && (
        <Alert
          severity="error"
          sx={{
            marginTop: "20px",
            backgroundColor: "#FF4C4C",
            color: "#FFFFFF",
          }}
        >
          {errorMessage}
        </Alert>
      )}

      {successMessage && (
        <Alert
          severity="success"
          sx={{
            marginTop: "20px",
            backgroundColor: "#4CAF50",
            color: "#FFFFFF",
          }}
        >
          {successMessage}
        </Alert>
      )}

      <Button
        type="submit"
        variant="contained"
        fullWidth
        sx={{
          marginTop: "20px",
          backgroundColor: "#4CAF50",
          color: "#fff",
          "&:hover": { backgroundColor: "#45A049" },
        }}
        disabled={loading}
      >
        {loading ? (
          <CircularProgress size={24} color="inherit" />
        ) : (
          "Reset Password"
        )}
      </Button>
    </Box>
  );
};

export default ResetPassword;

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import {
  Box,
  Typography,
  TextField,
  MenuItem,
  Button,
  CircularProgress,
  Alert,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const UploadLecture = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  //const isMobile = useMediaQuery('(max-width:780px)');
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState(false);
  const [uploadStatus, setUploadStatus] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const maxFileSize = 10 * 1024 * 1024; // 5MB file size limit

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decodedToken = JSON.parse(atob(token.split(".")[1]));
      setIsLoggedIn(decodedToken.isLoggedIn);
    }
  }, []);
  const token1 = localStorage.getItem("token");
  let decodedToken1 = {};
  if (token1) {
    decodedToken1 = JSON.parse(atob(token1.split(".")[1]));
  }
  const onSubmit = async (data) => {
    setLoading(true);
    setUploadStatus("Processing...");

    const formData = new FormData();
    formData.append("month", data.month);
    formData.append("week", data.week);
    formData.append("subject", data.subject);
    formData.append("author", data.author || "Anonymous");
    formData.append("file", data.file[0]);

    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/lectures/upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: isLoggedIn
              ? `Bearer ${localStorage.getItem("token")}`
              : undefined,
          },
        }
      );
      setUploadStatus(
        isLoggedIn
          ? "Lecture successfully uploaded!"
          : "Lecture uploaded, awaiting admin approval."
      );
    } catch (error) {
      setUploadStatus(
        error.response?.data.message || "An error occurred while uploading."
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ maxWidth: "500px", margin: "0 auto", padding: "20px", backgroundColor: "#282c34", borderRadius: "8px", color: "#fff" }}>
      <Typography variant="h4" gutterBottom>
        Upload Lecture
      </Typography>
      <Alert
                severity="warning" // You can change this to "success", "warning", or "error"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  backgroundColor: '#282c34', // Your desired background color
                  color: '#fff',
                }}
              >              
                 <Typography variant="h6" sx={{ fontWeight: isMobile ? 'bold' : 'bold', fontSize: isMobile ? '0.99rem' : '1.25rem'}}>
                 {token1 ? 
  `Dear ${decodedToken1.username},
  If you prefer not to have your name listed as the author, you can remove it below. You can always update this later in the MyLectures tab.` :
  `To ensure your lectures are approved right away and to have the ability to edit them at any time, please log in or sign up first and upload your lectures.`
}
<Box component="span" sx={{ marginLeft: '5px' }}></Box>
                </Typography>
              </Alert>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          fullWidth
          select
          label="Month"
          variant="outlined"
          {...register("month", { required: "Month is required" })}
          error={!!errors.month}
          helperText={errors.month?.message}
          margin="normal"
          InputLabelProps={{ style: { color: "#fff" } }}
          InputProps={{ style: { color: "#fff" } }}
        >
          <MenuItem value="">Select Month</MenuItem>
          <MenuItem value="October">October</MenuItem>
          <MenuItem value="November">November</MenuItem>
          {/* <MenuItem value="December">December</MenuItem> */}
        </TextField>
        <TextField
          fullWidth
          select
          label="Week"
          variant="outlined"
          {...register("week", { required: "Week is required" })}
          error={!!errors.week}
          helperText={errors.week?.message}
          margin="normal"
          InputLabelProps={{ style: { color: "#fff" } }}
          InputProps={{ style: { color: "#fff" } }}
        >
          <MenuItem value="">Select Week</MenuItem>
          <MenuItem value="Week 1">Week 1</MenuItem>
          <MenuItem value="Week 2">Week 2</MenuItem>
          <MenuItem value="Week 3">Week 3</MenuItem>
          <MenuItem value="Week 4">Week 4</MenuItem>
          <MenuItem value="Week 5">Week 5</MenuItem>
        </TextField>
        <TextField
          fullWidth
          select
          label="Subject"
          variant="outlined"
          {...register("subject", { required: "Subject is required" })}
          error={!!errors.subject}
          helperText={errors.subject?.message}
          margin="normal"
          InputLabelProps={{ style: { color: "#fff" } }}
          InputProps={{ style: { color: "#fff" } }}
        >
          <MenuItem value="">Select Subject</MenuItem>
          <MenuItem value="Arabic">Arabic</MenuItem>
          <MenuItem value="Comparative Literature">Comparative Literature</MenuItem>
          <MenuItem value="Conversation Skills">Conversation Skills</MenuItem>
          <MenuItem value="Essay">Essay</MenuItem>
          <MenuItem value="Literary Criticism">Literary Criticism</MenuItem>
          <MenuItem value="Sociolinguistics">Sociolinguistics</MenuItem>
          <MenuItem value="Stylistics ">Stylistics </MenuItem>
          <MenuItem value="Translation">Translation</MenuItem>
        </TextField>
        <TextField
          fullWidth
          label="Author (optional)"
          variant="outlined"
          defaultValue={decodedToken1.username}
          {...register("author")}
          margin="normal"
          InputLabelProps={{ style: { color: "#fff" } }}
          InputProps={{ style: { color: "#fff" } }}
        />
        <TextField
          fullWidth
          type="file"
          variant="outlined"
          {...register("file", {
            required: "File is required",
            validate: {
              acceptedFormats: (files) =>
                files[0]?.type === "application/pdf" ||
                "Only PDF files are allowed",
              fileSizeLimit: (files) =>
                files[0]?.size <= maxFileSize ||
                `File size exceeds the 10MB limit`,
            },
          })}
          error={!!errors.file}
          helperText={
            errors.file?.message || "Max file size: 10MB. Accepted: PDF only."
          }
          margin="normal"
          InputLabelProps={{ style: { color: "#fff" } }}
          InputProps={{ style: { color: "#fff" } }}
        />
        <Button
          type="submit"
          variant="contained"
          fullWidth
          disabled={loading}
          sx={{ mt: 2, backgroundColor: "#555", color: "#fff" }}
        >
          {loading ? <CircularProgress size={24} /> : "Upload Lecture"}
        </Button>
        {uploadStatus && (
          <Alert
            severity={uploadStatus.includes("success") ? "success" : "error"}
            sx={{ mt: 2 }}
          >
            {uploadStatus}
          </Alert>
        )}
      </form>
    </Box>
  );
};

export default UploadLecture;

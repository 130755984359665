import { useEffect, useState } from "react";

const useAuth = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token"); // Assuming you're storing JWT in localStorage

    if (token) {
      const decoded = JSON.parse(atob(token.split(".")[1]));
      setIsLoggedIn(true);
      setIsAdmin(decoded.isAdmin); // Assuming isAdmin is part of your token payload
    }
  }, []);

  return { isLoggedIn, isAdmin };
};

export default useAuth;

import { Link } from 'react-router-dom';
import axios from 'axios';
import { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  Alert,
  Dialog,
  DialogTitle,
  DialogActions,
} from '@mui/material';

const MyLectures = () => {
  const [lectures, setLectures] = useState([]);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [openDialog, setOpenDialog] = useState(false); // State for dialog visibility
  const [selectedLectureId, setSelectedLectureId] = useState(null); // State to hold the lecture ID for deletion
  const [selectedMonth, setSelectedMonth] = useState('October'); // State to hold the selected month
  const [availableMonths] = useState(['October', 'November', 'December']); // Available months

  useEffect(() => {
    const fetchLectures = async () => {
      try {
        const token = localStorage.getItem('token');
        // Fetching lectures based on the selected month
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/lectures/my-lectures?month=${selectedMonth}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setLectures(response.data);
      } catch (err) {
        setError('Failed to fetch lectures.');
      }
    };

    fetchLectures();
  }, [selectedMonth]); // Re-fetch lectures when the selected month changes

  const handleDelete = (id) => {
    setSelectedLectureId(id); // Set the selected lecture ID for deletion
    setOpenDialog(true); // Open the confirmation dialog
  };

  const confirmDelete = async () => {
    try {
      const token = localStorage.getItem('token');
      await axios.delete(`${process.env.REACT_APP_API_URL}/api/lectures/${selectedLectureId}?month=${selectedMonth}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLectures(lectures.filter((lecture) => lecture._id !== selectedLectureId));
      setSuccess('Lecture deleted successfully.');
    } catch (err) {
      setError('Failed to delete lecture.');
    } finally {
      setOpenDialog(false); // Close the dialog after the operation
    }
  };

  const handleMonthChange = (direction) => {
    const currentIndex = availableMonths.indexOf(selectedMonth);
    let newIndex = currentIndex;

    if (direction === 'previous' && currentIndex > 0) {
      newIndex = currentIndex - 1;
    } else if (direction === 'next' && currentIndex < availableMonths.length - 1) {
      newIndex = currentIndex + 1;
    }

    setSelectedMonth(availableMonths[newIndex]);
  };

  return (
    <Box sx={{ padding: 2, backgroundColor: "#282c34", color: "#fff", borderRadius: "8px" }}>
      <Typography variant="h4" gutterBottom>
        My Lectures ({selectedMonth})
      </Typography>
      {error && <Alert severity="error">{error}</Alert>}
      {success && <Alert severity="success">{success}</Alert>}

      {/* Month Navigation */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Button
          variant="contained"
          onClick={() => handleMonthChange('previous')}
          disabled={selectedMonth === availableMonths[0]}
        >
          Previous Month
        </Button>
        <Button
          variant="contained"
          onClick={() => handleMonthChange('next')}
          disabled={selectedMonth === availableMonths[availableMonths.length - 1]}
        >
          Next Month
        </Button>
      </Box>

      <ul>
        {lectures.map((lecture) => (
          <li key={lecture._id}>
            <h3>{lecture.title}</h3>
            <p>Subject: {lecture.subject}</p>
            <p>Author: {lecture.author}</p>
            <Button
              variant="contained"
              color="primary"
              href={lecture.fileUrl}
              target="_blank"
              rel="noopener noreferrer"
              sx={{ ml: 2 }}
            >
              Download
            </Button>
            <Button
              variant="contained"
              color="secondary"
              component={Link}
              to={`/edit-lecture/${lecture._id}?month=${selectedMonth}&?week=${lecture.week}`} // Pass the selected month to the edit page
              sx={{ ml: 2 }}
            >
              Edit
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => handleDelete(lecture._id)}
              sx={{ ml: 2 }}
            >
              Delete
            </Button>
          </li>
        ))}
      </ul>

      {/* Confirmation Dialog */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmDelete} color="error">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default MyLectures;

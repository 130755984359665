import React, { useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  TextField,
  Button,
  CircularProgress,
  Typography,
  Alert,
  Box,
  Tooltip,
  Link,
} from "@mui/material";

// Validation schema using Yup
const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
  password: Yup.string().required("Password is required"),
});

const Register = () => {
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data) => {
    setLoading(true);
    setSuccessMessage("");
    setErrorMessage("");

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/auth/register`,
        data
      );
      setSuccessMessage(response.data); // Assuming backend sends a success message
    } catch (error) {
      setErrorMessage(error.response?.data?.error || "Registration failed");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        maxWidth: "400px",
        margin: "50px auto",
        padding: "30px",
        backgroundColor: "#2C2C2C",
        borderRadius: "12px",
        boxShadow: "0 4px 20px rgba(0, 0, 0, 0.5)",
      }}
    >
      <Typography
        variant="h4"
        align="center"
        gutterBottom
        sx={{ color: "#FFFFFF" }}
      >
        Register
      </Typography>

      <Tooltip title="Enter your full name" arrow>
        <TextField
          fullWidth
          label="Name"
          variant="outlined"
          {...register("name")}
          error={!!errors.name}
          helperText={errors.name?.message}
          margin="normal"
          InputLabelProps={{ style: { color: "#B0B0B0" } }}
          InputProps={{ style: { color: "#FFFFFF" } }}
          sx={{
            "&:focus-within": { borderColor: "#4CAF50" },
            "&:hover": { borderColor: "#4CAF50" },
            borderColor: "#555",
            bgcolor: "#444",
          }}
        />
      </Tooltip>

      <Tooltip title="Enter your registered email address" arrow>
        <TextField
          fullWidth
          label="Email"
          variant="outlined"
          {...register("email")}
          error={!!errors.email}
          helperText={errors.email?.message}
          margin="normal"
          InputLabelProps={{ style: { color: "#B0B0B0" } }}
          InputProps={{ style: { color: "#FFFFFF" } }}
          sx={{
            "&:focus-within": { borderColor: "#4CAF50" },
            "&:hover": { borderColor: "#4CAF50" },
            borderColor: "#555",
            bgcolor: "#444",
          }}
        />
      </Tooltip>

      <Tooltip title="Create a secure password" arrow>
        <TextField
          fullWidth
          label="Password"
          type="password"
          variant="outlined"
          {...register("password")}
          error={!!errors.password}
          helperText={errors.password?.message}
          margin="normal"
          InputLabelProps={{ style: { color: "#B0B0B0" } }}
          InputProps={{ style: { color: "#FFFFFF" } }}
          sx={{
            "&:focus-within": { borderColor: "#4CAF50" },
            "&:hover": { borderColor: "#4CAF50" },
            borderColor: "#555",
            bgcolor: "#444",
          }}
        />
      </Tooltip>
      {successMessage && (
        <Alert
          severity="success"
          sx={{
            marginTop: "20px",
            backgroundColor: "#4CAF50",
            color: "#FFFFFF",
          }}
        >
          {successMessage}
        </Alert>
      )}

      {errorMessage && (
        <Alert
          severity="error"
          sx={{
            marginTop: "20px",
            backgroundColor: "#FF4C4C",
            color: "#FFFFFF",
          }}
        >
          {errorMessage}
        </Alert>
      )}
{/* Reminder */}
<Box sx={{ marginTop: "15px", textAlign: "center" }}> 
        <Typography variant="body2" sx={{ color: "#B0B0B0" }}>
        Reminder: Before your registration can be approved by an admin, you must confirm your email. Click 'Register' to continue.</Typography>
      </Box>
<Box sx={{ marginTop: "15px", textAlign: "center" }}> 
        
      </Box>
      <Button
        type="submit"
        variant="contained"
        fullWidth
        sx={{
          marginTop: "20px",
          backgroundColor: "#4CAF50",
          color: "#fff",
          "&:hover": { backgroundColor: "#45A049" },
        }}
        disabled={loading}
      >
        {loading ? <CircularProgress size={24} color="inherit" /> : "Register"}
      </Button>
      {/* Already have an account ? */}
      <Box sx={{ marginTop: "15px", textAlign: "center" }}> 
        <Typography variant="body2" sx={{ color: "#B0B0B0" }}>
          Already have an account?{" "}
          <Link href="/login" style={{ color: "#4CAF50" }}>
            Login
          </Link>
        </Typography>
      </Box>
    </Box>
  );
};

export default Register;
